import { createRouter, createWebHashHistory } from "vue-router";

//路由懒加载
const Home = () =>
  import(/* webpackChunkName: "router-home" */ "../views/home.vue");
const HomeEN = () =>
  import(/* webpackChunkName: "router-home-EN" */ "../views/home-EN.vue");
const Page1 = () =>
  import(/* webpackChunkName: "router-page1" */ "../views/page1.vue");
const Page1EN = () =>
  import(/* webpackChunkName: "router-page1-EN" */ "../views/page1-EN.vue");
const Page2 = () =>
  import(/* webpackChunkName: "router-page2" */ "../views/page2.vue");
const Page2EN = () =>
  import(/* webpackChunkName: "router-page2-EN" */ "../views/page2-EN.vue");
const Page3 = () =>
  import(/* webpackChunkName: "router-page3" */ "../views/page3.vue");
const Page3EN = () =>
  import(/* webpackChunkName: "router-page3-EN" */ "../views/page3-EN.vue");
const Page4 = () =>
  import(/* webpackChunkName: "router-page4" */ "../views/page4.vue");
const Page4EN = () =>
  import(/* webpackChunkName: "router-page4-EN" */ "../views/page4-EN.vue");
const Page5 = () =>
  import(/* webpackChunkName: "router-page5" */ "../views/page5.vue");
const Page5EN = () =>
  import(/* webpackChunkName: "router-page5-EN" */ "../views/page5-EN.vue");
const Page6 = () =>
  import(/* webpackChunkName: "router-page6" */ "../views/page6.vue");
const Page6EN = () =>
  import(/* webpackChunkName: "router-page6-EN" */ "../views/page6-EN.vue");
const Page7 = () =>
  import(/* webpackChunkName: "router-page7" */ "../views/page7.vue");
const Page7EN = () =>
  import(/* webpackChunkName: "router-page7-EN" */ "../views/page7-EN.vue");
const Page8 = () =>
  import(/* webpackChunkName: "router-page8" */ "../views/page8.vue");
const Page8EN = () =>
  import(/* webpackChunkName: "router-page8-EN" */ "../views/page8-EN.vue");
const Page9 = () =>
  import(/* webpackChunkName: "router-page9" */ "../views/page9.vue");
const Page9EN = () =>
  import(/* webpackChunkName: "router-page9-EN" */ "../views/page9-EN.vue");
const Page10 = () =>
  import(/* webpackChunkName: "router-page10" */ "../views/page10.vue");
const Page10EN = () =>
  import(/* webpackChunkName: "router-page10-EN" */ "../views/page10-EN.vue");
import(/* webpackChunkName: "router-page10" */ "../views/page10.vue");
const Page13 = () =>
  import(/* webpackChunkName: "router-page13" */ "../views/page13.vue");
const Page13EN = () =>
  import(/* webpackChunkName: "router-page13-EN" */ "../views/page13-EN.vue");
// const ProductMall = () => import(/* webpackChunkName: "router-product-mall" */ '../views/product-mall.vue')
// const AboutUs = () => import(/* webpackChunkName: "router-about-us" */ '../views/about-us.vue')
// const CooperationCases = () => import(/* webpackChunkName: "router-cooperation-cases" */ '../views/cooperation-cases.vue')
// const NewsInformation = () => import(/* webpackChunkName: "router-news-information" */ '../views/news-information.vue')
// const JoinUs = () => import(/* webpackChunkName: "router-join-us" */ '../views/join-us.vue')
// const ContactUs = () => import(/* webpackChunkName: "router-contact-us" */ '../views/contact-us.vue')
// const Content = () => import(/* webpackChunkName: "router-content" */ '../views/content.vue')
// const SourceList = () => import(/* webpackChunkName: "router-source-list" */ '../views/source-list.vue')

export default createRouter({
  history: createWebHashHistory(),
  routes: [
    { path: "/:patMatch(.*)*", component: Home },
    { path: "/", component: Home },
    { path: "/home", component: Home },
    { path: "/home/en", component: HomeEN },
    { path: "/products/automation", component: Page1 },
    { path: "/products/automation/en", component: Page1EN },
    { path: "/products/machine-vision", component: Page2 },
    { path: "/products/machine-vision/en", component: Page2EN },
    { path: "/products/industrial-robots", component: Page3 },
    { path: "/products/industrial-robots/en", component: Page3EN },
    { path: "/products/safety-system", component: Page4 },
    { path: "/products/safety-system/en", component: Page4EN },
    { path: "/products/traceability", component: Page5 },
    { path: "/products/traceability/en", component: Page5EN },
    { path: "/products/machine-tool", component: Page6 },
    { path: "/products/machine-tool/en", component: Page6EN },
    { path: "/solutions/engineering-proposal", component: Page7 },
    { path: "/solutions/engineering-proposal/en", component: Page7EN },
    { path: "/solutions/applications", component: Page8 },
    { path: "/solutions/applications/en", component: Page8EN },
    { path: "/about-us", component: Page9 },
    { path: "/about-us/en", component: Page9EN },
    { path: "/join-us", component: Page10 },
    { path: "/join-us/en", component: Page10EN },
    { path: "/Contact", component: Page13 },
    { path: "/Contact/en", component: Page13EN },
    // {
    //     name: 'content', path: '/content', component: Content,
    //     props: route => ({ refid: route.query.refid })
    // },
    // {
    //     name: 'source-list', path: '/source-list', component: SourceList,
    //     props: route => ({ category: route.query.category, tag: route.query.tag })
    // },
  ],
  // return 期望滚动到哪个的位置
  scrollBehavior(to, from, savedPosition) {
    if (savedPosition) {
      return savedPosition;
    } else {
      // vue2.0  x  y  控制
      // vue3.0  left  top 控制
      return { left: 0, top: 0 };
    }
  },
});
