<template>
  <div class="app-center-panel">
    <transition name="fade">
      <router-view :key="key"></router-view>
    </transition>
  </div>
</template>

<script>

export default {
  name: 'App',
  components: {
  }
}
</script>

<style>
#app {
  font-family: HarmonyOS Sans SC Black;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  text-align: center;
  color: #2c3e50;
}

.txt-danger {
  color: #FF0000 !important;
}
</style>
